const { validate } = require("rut.js");

const isValidRut = (rut) => {
  if (rut && rut.length > 0) {
    const tempRut = rut.toLowerCase();
    const checkHyphen = tempRut.split("-");
    if (checkHyphen[1]) {
      // eslint-disable-next-line no-debugger
      return validate(tempRut);
    }
  }

  return false;
};

const isValidEmail = (email) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  return pattern.test(email);
};

export { isValidRut, isValidEmail };
