import httpRequest from "../http/HttpRequest";
import { getOrganization } from "./OrganizationService";

const organization = getOrganization();

const getPeriods = async () => {

  const data = {
    query: `{
            periods(search: {organizationId: "${organization.id}"}) {
            id
            description
            hourStart
            hourEnd
          }
        }`,
  };
  const response = await httpRequest.postRequest(``, data);
  return response.data?.data.periods || [];
};

const getLocations = async () => {
  const data = {
    query: ` query($search: LocationSearchInput!){
            locations(search: $search){
                id
                name
                amount
            }
        }`,
    variables: {
      search: {
        organizationId: organization.id,
      },
    },
  };
  const response = await httpRequest.postRequest(``, data);
  return response.data?.data.locations || [];
};

const getCalendar = async (year, month) => {
  const data = {
    query: ` query($search: CalendarSearchInput!){
            calendar(search: $search){
                scheduleId
                date
                periods {
                    slots 
                    period {
                        id
                    }
                }
            }
        }`,
    variables: {
      search: {
        organizationId: organization.id,
        year: year,
        month: month,
      },
    },
  };
  const response = await httpRequest.postRequest(``, data);
  const calendar = response.data?.data.calendar || [];
  return calendar;
};

const getAppointment = async (id) => {
  const data = {
    query: ` query($search: ID!){
            appointment:appointment(id: $search){
                id
                date
                amount
                createdTime
                service {
                    name
                    description
                }
                location {
                    name
                }
                period {
                    hourStart
                    hourEnd
                }
                patients {
                    firstName
                    lastName
                    address
                }   
            }
        }`,
    variables: {
      search: id,
    },
  };
  const response = await httpRequest.postRequest(``, data);
  const appointment = response.data?.data.appointment || [];
  return appointment;
};

export { getPeriods, getLocations, getCalendar, getAppointment };
