import organizations from "./organizations.json";

const getOrganization = () => {
    let subdomain = window.location.hostname;
    console.log("full subdomain", subdomain);

    if (subdomain.includes('.simpp.ly')) {
        subdomain = subdomain.substring(0, subdomain.indexOf('.simpp.ly'));
    } else {
        subdomain = 'dev';
    }
    console.log("subdomain name", subdomain);

    const organization = organizations.find(org => org.domain === subdomain);

    console.log("organization", organization)
    return organization;
}

export {getOrganization}