import React from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./header.module.scss";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <Container maxWidth={false} className={styles.wrapper}>
      <Container>
        <Grid
          container
          className={styles.header}
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Link to={"/"} className={styles.logo}>
              Logo
            </Link>
          </Grid>
          <Grid item>
            <a href={"#"}>
              Volver a <b>Andes Salud</b>
            </a>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Header;
