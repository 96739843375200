import httpRequest from "../http/HttpRequest";
import { getOrganization } from "./OrganizationService";


const getAll = async () => {
    const organization = getOrganization();

    const data = {
        query : `{
            services(search: {organizationId: "${organization.id}"}) {
            id
            name
            amount
            description
          }
        }`
    };
    const response = await httpRequest.postRequest(``, data);
    return response.data?.data.services || [];
}

export {getAll}