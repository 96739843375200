import {Card, CardActionArea, CardContent, Grid, Typography} from "@material-ui/core";
import clsx from "clsx";
import styles from "./service-list.module.scss";


const ServiceList = ({services, onSelectService, selectedService}) => {
    return (
        <>
            {services.map(service => (
                <div key={service.id}>
                    <Card className={clsx({
                        [styles.default]: true,
                        [styles.active]: selectedService?.id === service.id})
                    }>
                        <CardActionArea onClick={() => {  onSelectService(service)}}>
                            <CardContent classes={{
                                root: styles.card_content
                            }}>
                                <Typography className={styles.service_name}>{service.name}</Typography>
                                <Typography className={styles.service_result}></Typography>
                                <Typography className={styles.service_description}>{service.description}</Typography>
                                <Typography className={styles.service_amount}>Valor total por examen: <span className={styles.service_amount_value}>${service.amount}</span></Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            ))}
        </>
    );
}

export default ServiceList;