import httpRequest from "../http/HttpRequest";
import { getOrganization } from "./OrganizationService";


const bookAppointment = async (data) => {
  const patients = data.patients.map((patient) => {
    return {
      firstName: patient.name,
      lastName: patient.lastname,
      document: patient.documentNumber,
      documentType: patient.documentType,
      email: patient.email,
      phone: patient.phone,
      address: patient.street,
      birthdate: patient.birthdate,
    };
  });

  const amount = patients.length * data.service.amount;
  const organization = getOrganization();

  const input = {
    date: data.calendar.date,
    organizationId: organization.id,
    serviceId: data.service.id,
    scheduleId: data.calendar.scheduleId,
    periodId: data.time.id,
    customer: {
      firstName: "David",
      lastName: "lastName",
      document: "12345679",
      documentType: "RUT",
      email: "prueba@email.com",
    },
    patients: patients,
    locationId: data.location.id,
    amount: amount,
  };

  const request = {
    query: ` mutation ($input: AppointmentCreateInput!){
            appointmentCreate(input: $input){
                id
            }
        }`,
    variables: {
      input: input,
    },
  };
  const response = await httpRequest.postRequest(``, request);
  return response.data?.data.appointmentCreate || [];
};

export { bookAppointment };
