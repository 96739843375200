import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import AppointmentPage from "./pages/AppointmentPage";
import PatientsPage from "./pages/PatientsPage";
import PaymentPage from "./pages/PaymentPage";
import ConfirmationPage from "./pages/ConfirmationPage";

const Routes = () => {
    return(
     <Switch>
         <Route path={"/"} exact>
            <Home/>
         </Route>
         <Route path={"/cita"} exact>
             <AppointmentPage/>
         </Route>

         <Route path={"/pacientes"} exact>
             <PatientsPage/>
         </Route>

         <Route path={"/pago"} exact>
             <PaymentPage/>
         </Route>

         <Route path={"/confirmacion/:id"}>
             <ConfirmationPage/>
         </Route>
     </Switch>
    )
}

export default Routes;