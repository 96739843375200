import React from "react";
import {Typography} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from "clsx";

const TimePicker = ({times, selectedTime, onSelectTime}) => {

    return (
        times.map( (time) =>
            <div key={time.id} className={clsx({
                "time-container": true,
                "selected": selectedTime?.id === time.id,
                "disabled": !time.isAvailable})
            }  onClick={() => time.isAvailable && onSelectTime(time)}>
                    <Typography variant={"body1"} component={"h2"}>
                        {time.description}
                        {selectedTime?.id === time.id && <CheckCircleIcon style={{float: "right"}}/>}
                    </Typography>
            </div>
        )
    )
}

export default TimePicker;