import React, { useContext, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Stepper2 from "../assets/Stepper2.svg";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Add, DateRange, EmailOutlined, Phone } from "@material-ui/icons";
import PatientsList from "../components/PatientsList";
import { Form, Formik, FormikConsumer, useFormik } from "formik";
import * as yup from "yup";
import BookingSummary from "../components/booking-summary/BookingSummary";
import { BookingContext } from "../providers/BookingProvider";
import { isValidRut } from "../validators/validators";

const PatientForm = ({ onAddPatient, handleSubmit }) => {
  const { booking } = useContext(BookingContext);
  const validations = yup.object({
    name: yup.string().required("El nombre es requerido"),
    lastname: yup.string().required("El apellido es requerido"),
    documentType: yup.string().required("El tipo de documento es requerido"),
    documentNumber: yup
      .string()
      .required("El número de documento es requerido")
      .when("documentType", {
        is: "RUT",
        then: yup
          .string()
          .test("isValidRUT", "${path} no es un RUT", (value) =>
            isValidRut(value)
          ),
      }),
    email: yup
      .string()
      .email("El correo electrónico es inválido")
      .required("El correo electrónico es requerido"),
    birthdate: yup.string().required("La fecha de nacimiento es requerida"),
    street: yup.string().required("La dirección es requerida"),
    addressType: yup.string().required("El tipo de vivienda es requerido"),
    departmentNumber: yup.string().when("addressType", {
      is: "department",
      then: yup.string().required("El número es requerido"),
    }),
    phone: yup.number().required("El teléfono es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      documentType: "",
      documentNumber: "",
      email: "",
      birthdate: "",
      commune: booking?.location?.name,
      street: "",
      addressType: "",
      departmentNumber: "",
      phone: "",
    },
    validationSchema: validations,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleAddPatient = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      const values = formik.values;
      onAddPatient(values);
      formik.handleReset();
      formik.setValues(
        {
          street: values.street,
          addressType: values.addressType,
          commune: booking?.location?.name,
          departmentNumber: values.departmentNumber,
        },
        false
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} id={"form1"}>
      <Grid item md={12}>
        <Typography
          variant={"h5"}
          component={"h2"}
          style={{
            marginTop: "3.5rem",
            marginBottom: "1.5rem",
            color: "#6A6D81",
          }}
        >
          Ingresa la dirección:
        </Typography>

        <Grid item container md={9} style={{ marginBottom: "1rem" }}>
          <Grid item md={8} style={{ paddingRight: "1rem" }}>
            <TextField
              id={"street"}
              name={"street"}
              onChange={formik.handleChange}
              value={formik.values.street}
              error={Boolean(formik.errors.street)}
              helperText={formik.errors.street}
              fullWidth
              label={"Calle y Número"}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              id={"commune"}
              name={"commune"}
              value={formik.values.commune}
              helperText={formik.errors.commune}
              fullWidth
              label={"Comuna"}
              readonly
              disabled
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid item container md={9} style={{ marginBottom: "1rem" }}>
          <Grid
            item
            md={5}
            style={{ marginBottom: "1rem", paddingRight: "1rem" }}
          >
            <TextField
              id="addressType"
              name="addressType"
              select
              label="Tipo de vivienda"
              onChange={formik.handleChange}
              value={formik.values.addressType}
              error={Boolean(formik.errors.addressType)}
              helperText={formik.errors.addressType}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="house">Casa</MenuItem>
              <MenuItem value="department">Departamento</MenuItem>
            </TextField>
          </Grid>
          <Grid
            item
            md={3}
            style={{ marginBottom: "1rem", paddingRight: "1rem" }}
          >
            {formik.values.addressType === "department" && (
              <TextField
                fullWidth
                label={"Número"}
                id={"departmentNumber"}
                name={"departmentNumber"}
                value={formik.values.departmentNumber}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.departmentNumber)}
                helperText={formik.errors.departmentNumber}
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container md={9}>
        <Grid item md={9}>
          <Typography
            variant={"h5"}
            component={"h2"}
            style={{
              marginBottom: "1.5rem",
              marginTop: "2.5rem",
              color: "#6A6D81",
            }}
          >
            Ingresa los datos del paciente:
          </Typography>
        </Grid>
        <Grid item md={1}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={handleAddPatient}
          >
            <Add /> Nuevo{" "}
          </Button>
        </Grid>
      </Grid>
      <Grid item md={9} style={{ marginBottom: "1rem" }}>
        <TextField
          fullWidth
          label={"Nombre"}
          id={"name"}
          name={"name"}
          variant="outlined"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
      </Grid>
      <Grid item md={9} style={{ marginBottom: "1rem" }}>
        <TextField
          fullWidth
          label={"Apellido"}
          id={"lastname"}
          name={"lastname"}
          variant="outlined"
          value={formik.values.lastname}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.lastname)}
          helperText={formik.errors.lastname}
        />
      </Grid>
      <Grid item md={9} style={{ marginBottom: "1rem" }}>
        <FormControl
          component="fieldset"
          error={Boolean(formik.errors.documentType)}
        >
          <RadioGroup
            aria-label="documentType"
            id={"documentType"}
            name={"documentType"}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.documentType}
            style={{ display: "block" }}
          >
            <FormControlLabel
              value="RUT"
              control={<Radio />}
              label="RUT"
              style={{ float: "left" }}
            />
            <FormControlLabel
              value="Pasaporte"
              control={<Radio />}
              style={{ float: "left" }}
              label="Pasaporte"
            />
          </RadioGroup>
          <FormHelperText>{formik.errors.documentType}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item md={9} style={{ marginBottom: "1rem" }}>
        <TextField
          fullWidth
          label={"Número de documento"}
          value={formik.values.documentNumber}
          id={"documentNumber"}
          name={"documentNumber"}
          variant="outlined"
          onKeyUp={
            formik.values.documentType === "RUT"
              ? (event) => {
                  const completeRut = event.target.value.replace(
                    /[^0-9kK]/g,
                    ""
                  );
                  const dv = completeRut.slice(-1);
                  const rutWithOutDv = completeRut.slice(0, -1);
                  event.target.value = `${rutWithOutDv}-${dv}`;
                  formik.handleChange(event);
                }
              : undefined
          }
          inputProps={
            formik.values.documentType === "RUT" ? { maxLength: 10 } : {}
          }
          onChange={formik.handleChange}
          error={Boolean(formik.errors.documentNumber)}
          helperText={formik.errors.documentNumber}
        />
      </Grid>

      <Grid item md={9} style={{ marginBottom: "1rem" }}>
        <TextField
          fullWidth
          label={"Fecha de nacimiento"}
          type="date"
          placeholder={"Fecha de nacimiento"}
          id={"birthdate"}
          name={"birthdate"}
          variant="outlined"
          value={formik.values.birthdate}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.birthdate)}
          helperText={formik.errors.birthdate}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DateRange style={{ color: "#597EF7" }} fontSize={"small"} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item md={9} style={{ marginBottom: "1rem" }}>
        <TextField
          fullWidth
          label={"Correo electrónico"}
          placeholder={"Correo electrónico"}
          id={"email"}
          name={"email"}
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.email)}
          helperText={formik.errors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlined
                  style={{ color: "#597EF7" }}
                  fontSize={"small"}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item md={9} style={{ marginBottom: "1rem" }}>
        <TextField
          fullWidth
          label={"Teléfono de contacto"}
          variant="outlined"
          placeholder={"Teléfono de contacto"}
          id={"phone"}
          name={"phone"}
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.phone)}
          helperText={formik.errors.phone}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone style={{ color: "#597EF7" }} fontSize={"small"} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </form>
  );
};

const PatientsPage = () => {
  const history = useHistory();
  const [patients, setPatients] = useState([]);

  const { booking, setBooking } = useContext(BookingContext);

  const onAddPatient = (values) => {
    setPatients([...patients, values]);
    setBooking({ ...booking, patients: [...patients, values] });
  };

  const onDelete = (patient) => {
    const newArray = patients.filter(
      (p) => p.patientDocumentNumber !== patient.patientDocumentNumber
    );
    setPatients(newArray);
  };

  const nextPage = () => {
    history.push("/pago");
  };

  const handleSubmit = (values) => {
    onAddPatient(values);
    nextPage();
  };

  return (
    <Container
      maxWidth={false}
      style={{ backgroundColor: "#F0F2F9", paddingTop: 60, minHeight: "100vh" }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <div
              style={{
                backgroundColor: "#FFF",
                padding: "4rem",
                borderRadius: 12,
              }}
            >
              <Grid
                container
                spacing={1}
                justify={"center"}
                direction={"column"}
              >
                <Grid item md={12}>
                  <img
                    src={Stepper2}
                    style={{
                      margin: "auto",
                      display: "block",
                      marginBottom: "1rem",
                    }}
                  />
                </Grid>

                <PatientForm
                  onAddPatient={onAddPatient}
                  handleSubmit={handleSubmit}
                />

                {patients.length > 0 && (
                  <Grid item md={12}>
                    <Typography style={{ color: "#6A6D81", marginBottom: 14 }}>
                      Pacientes registrados
                    </Typography>
                    <PatientsList patients={patients} onDelete={onDelete} />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={3}>
            <BookingSummary booking={booking} />
          </Grid>

          <Grid item xs={11}>
            <Button
              variant={"outlined"}
              color={"secondary"}
              style={{ float: "right" }}
              form={"form1"}
              type={"submit"}
            >
              Continuar <ArrowRightAltIcon />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default PatientsPage;
