import axios from "axios";

const { REACT_APP_API_URL } = process.env;

const baseURL = REACT_APP_API_URL;

const httpInstance = axios.create({ baseURL });

const defaultHeaders = {
    "Content-Type": "application/json",
};

const postRequest = async (resource, data, headers = null) => {
    return httpInstance.post(`${resource}`, data, {
        headers: headers || defaultHeaders,
    });
};

export default {
    postRequest
};
