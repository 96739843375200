import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { BookingContext } from "../../providers/BookingProvider";

const BookingSummary = () => {
  const { booking } = useContext(BookingContext);
  let total = 0.0;
  if (booking.patients && booking.patients.length > 0) {
    total =
      booking.location.amount +
      booking.service.amount * booking.patients.length;
  }

  return (
    <div
      style={{
        borderRadius: 16,
        backgroundColor: "#FFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div
        style={{
          backgroundColor: "#CCD7EE",
          borderRadius: "16px 16px 0px 0px",
          padding: "1.5rem",
          textAlign: "center",
        }}
      >
        <Typography
          variant={"h6"}
          style={{ color: "#4E5C93", fontWeight: "bold" }}
        >
          Mi reserva
        </Typography>
      </div>
      <div style={{ padding: "3rem", color: "#4E5C93" }}>
        <Typography style={{ padding: "6px 0" }}>Test solicitado:</Typography>
        <Typography
          style={{ padding: "6px 0", color: "#6A6D81" }}
          variant={"body2"}
        >
          {booking?.service?.name}
        </Typography>
        <Typography style={{ padding: "6px 0" }}>Día :</Typography>
        <Typography
          style={{ padding: "6px 0", color: "#6A6D81" }}
          variant={"body2"}
        >
          {booking?.date ? dayjs(booking?.date).format("DD/MM/YYYY") : ""}
        </Typography>
        <Typography style={{ padding: "6px 0" }}>Franja horaria:</Typography>
        <Typography
          style={{ padding: "6px 0", color: "#6A6D81" }}
          variant={"body2"}
        >
          {booking?.time?.description}
        </Typography>
        <Typography style={{ padding: "6px 0" }}>Comuna:</Typography>
        <Typography
          style={{ padding: "6px 0", color: "#6A6D81" }}
          variant={"body2"}
        >
          {booking?.location?.name}
        </Typography>
        {total > 0.0 && (
          <>
            <hr />
            <Typography
              style={{ padding: "6px", fontWeight: "bold" }}
              variant={"body2"}
            >
              Sub total
            </Typography>
            <Typography style={{ padding: "6px" }} variant={"body2"}>
              Total a pagar:
            </Typography>
            <Typography
              style={{ padding: "6px", color: "#6A6D81" }}
              variant={"body2"}
            >
              ${total}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default BookingSummary;
