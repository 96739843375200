import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { getAppointment } from "../services/ScheduleService";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Receipt from "@material-ui/icons/Receipt";
import Print from "@material-ui/icons/Print";
import dayjs from "dayjs";
require("dayjs/locale/es");
dayjs.locale("es");

const ConfirmationPage = () => {
  const [appointment, setAppointment] = useState({});
  const history = useHistory();
  const { id } = useParams();

  useEffect(async () => {
    const result = await getAppointment(id);
    setAppointment(result);
  }, []);

  useEffect(async () => {
    console.log(appointment);
  }, [appointment]);

  const ConfirmationDescription = ({ children, style }) => {
    return (
      <Typography
        style={{
          padding: "6px 0",
          fontSize: "18px",
          fontWeight: "900",
          color: "#4e5c94",
          ...style,
        }}
      >
        {children}
      </Typography>
    );
  };

  const ConfirmationValue = ({ children }) => {
    return (
      <Typography
        style={{
          padding: "6px 0",
          fontSize: "14px",
          color: "#6A6D81",
          float: "right",
        }}
        variant={"body2"}
      >
        {children}
      </Typography>
    );
  };

  const BackButton = () => {
    return (
      <Button
        variant={"contained"}
        disableElevation={true}
        size={"large"}
        style={{
          textTransform: "unset",
          marginTop: "20px",
          fontWeight: "800",
          fontSize: "16px",
          height: "36px",
          border: "1px solid #A5B5E5",
          backgroundColor: "#fff",
          color: "#597EF7",
          width: "17em",
        }}
        onClick={back}
      >
        Volver a Andes Salud
      </Button>
    );
  };

  const AppointmentButton = () => {
    return (
      <Button
        variant={"contained"}
        color={"secondary"}
        disableElevation={true}
        size={"large"}
        style={{
          textTransform: "unset",
          marginTop: "20px",
          fontWeight: "800",
          fontSize: "16px",
          height: "36px",
          width: "17em",
        }}
        onClick={goToAppointment}
      >
        Si, reservar nuevo turno
      </Button>
    );
  };

  const formatName = (customer) => {
    return customer.firstName + " " + customer.lastName;
  };

  const formatCurrency = (currency) => {
    return new Intl.NumberFormat("de-DE").format(currency);
  };

  const goToAppointment = () => {
    history.push("/");
  };

  const back = () => {
    history.push("/");
  };

  const printAppointment = () => {
    window.print();
  };

  return (
    <Container
      maxWidth={false}
      style={{ backgroundColor: "#F0F2F9", paddingTop: 60, height: "100vh" }}
    >
      <Container>
        <Grid
          container
          spacing={3}
          style={{
            borderRadius: 16,
            backgroundColor: "#FFF",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            padding: "3rem",
            color: "#4E5C93",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#daf4d5",
              borderRadius: "8px",
              padding: "1rem",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "2em",
            }}
          >
            <CheckCircleIcon
              style={{
                float: "left",
                fontSize: "2em",
                marginRight: "0.60em",
                color: "#29b92f",
              }}
            />{" "}
            <Typography
              variant={"h6"}
              style={{ color: "#536296", fontWeight: "bold" }}
            >
              Tu reserva ha sido completada con exíto!
            </Typography>
          </Grid>
          <Grid container item xs={6} style={{ paddingLeft: 0 }}>
            <Grid
              container
              style={{
                backgroundColor: "#fffafa",
                borderRadius: "4px",
                padding: "1em 4em 1em 2em",
              }}
            >
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <ConfirmationDescription>
                    Test solicitado:
                  </ConfirmationDescription>
                </Grid>
                <Grid item xs={6}>
                  <ConfirmationValue>
                    {appointment?.service?.name}
                  </ConfirmationValue>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={6}>
                  <ConfirmationDescription>Día:</ConfirmationDescription>
                </Grid>
                <Grid item xs={6}>
                  <ConfirmationValue>
                    {appointment?.date
                      ? dayjs(appointment?.date).format("DD/MM/YYYY")
                      : ""}
                  </ConfirmationValue>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={6}>
                  <ConfirmationDescription>
                    Franja horaria:
                  </ConfirmationDescription>
                </Grid>
                <Grid item xs={6}>
                  {appointment?.period && (
                    <ConfirmationValue>
                      {appointment?.period?.hourStart} -{" "}
                      {appointment?.period?.hourEnd}hs
                    </ConfirmationValue>
                  )}
                  {appointment?.time && (
                    <ConfirmationValue>
                      {appointment?.time?.description}
                    </ConfirmationValue>
                  )}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={6}>
                  <ConfirmationDescription>Comuna:</ConfirmationDescription>
                </Grid>
                <Grid item xs={6}>
                  <ConfirmationValue>
                    {appointment?.location?.name}
                  </ConfirmationValue>
                </Grid>
              </Grid>
              {appointment?.patients &&
                appointment.patients.map((patient, i) => (
                  <Grid item xs={12} key={i}>
                    <Grid container item>
                      <Grid item xs={6}>
                        <ConfirmationDescription>
                          Paciente 0{i + 1}:
                        </ConfirmationDescription>
                      </Grid>
                      <Grid item xs={6}>
                        <ConfirmationValue>
                          {formatName(patient)}
                        </ConfirmationValue>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={6}>
                        <ConfirmationDescription>
                          Dirección:
                        </ConfirmationDescription>
                      </Grid>
                      <Grid item xs={6}>
                        <ConfirmationValue>{patient.address}</ConfirmationValue>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {appointment?.amount && (
                <>
                  <Grid item xs={12} style={{ padding: "1em 0 1em 0" }}>
                    <Divider style={{ backgroundColor: "#4E5C93" }} />
                  </Grid>
                  <Grid container item>
                    <Grid item xs={6}>
                      <ConfirmationDescription style={{ fontWeight: "400" }}>
                        Sub total:
                      </ConfirmationDescription>
                    </Grid>
                    <Grid item xs={6}>
                      <ConfirmationValue>
                        {formatCurrency(appointment?.amount)}
                      </ConfirmationValue>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={6}>
                      <ConfirmationDescription>
                        Total pagado:
                      </ConfirmationDescription>
                    </Grid>
                    <Grid item xs={6}>
                      <ConfirmationValue>
                        {formatCurrency(appointment?.amount)}
                      </ConfirmationValue>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              container
              item
              style={{
                marginTop: "2em",
              }}
            >
              <Grid item xs={12}>
                <ConfirmationDescription>
                  ¿Deseas hacer otra reserva?
                </ConfirmationDescription>
              </Grid>
              <Grid item xs={6}>
                <BackButton></BackButton>
              </Grid>
              <Grid item xs={6}>
                <AppointmentButton></AppointmentButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid
              container
              item
              style={{
                padding: "1em",
                paddingLeft: "3em",
                textAlign: "left",
                paddingTop: "0",
                height: "2em",
              }}
            >
              <Grid container item style={{ marginTop: 0 }}>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      padding: "0.3em",
                      backgroundColor: "#e2eefb",
                      borderRadius: "50%",
                    }}
                  >
                    <Receipt
                      style={{
                        float: "left",
                        fontSize: "1em",
                        color: "#0F56B3",
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={11}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <ConfirmationDescription>
                    N* de reserva: {appointment?.id}
                  </ConfirmationDescription>
                </Grid>
              </Grid>
              {/* <Grid 
                                container
                                item  
                                style={{marginTop: "1em"}}
                            >
                                <Grid item xs={1} />
                                <Grid 
                                    item 
                                    xs={11} 
                                >
                                    <Typography 
                                        style={{
                                            fontWeight: "900",
                                            color: "#363636",
                                        }}
                                    >
                                        Mastercard ....XXXX
                                    </Typography>
                                </Grid>
                            </Grid> */}
              <Grid container item style={{ marginTop: "0.5em" }}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <Typography
                    style={{
                      color: "#363636",
                      fontSize: "12px",
                    }}
                  >
                    {appointment?.createdTime
                      ? dayjs(appointment?.createdTime).format(
                          "DD [de] MMMM [de] YYYY"
                        )
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{ marginTop: "1.5em", cursor: "pointer" }}
                onClick={printAppointment}
              >
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Print
                    style={{
                      float: "left",
                      fontSize: "1.7em",
                      color: "#6A6D81",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={11}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#333333",
                      fontSize: "13px",
                      fontWeight: "900",
                    }}
                  >
                    Imprimir recibo
                  </Typography>
                </Grid>
              </Grid>

              {/* <Grid 
                                container
                                item  
                                style={{height: "19em"}}
                            >
                                <Grid 
                                    item
                                    xs={12}
                                    style={{
                                        height: "1.8em",
                                        background: "#E4EBF7",
                                        alignSelf: "flex-end",
                                        borderRadius: "1em",
                                        opacity: 0.5
                                    }} 
                                />
                            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default ConfirmationPage;
