import './App.scss';
import {BrowserRouter} from "react-router-dom";
import Routes from "./Routes";
import Header from "./components/header/Header";
import { StylesProvider } from "@material-ui/core/styles";
import BookingProvider from "./providers/BookingProvider";

function App() {
  return (
      <BrowserRouter>
          <StylesProvider injectFirst>
              <BookingProvider>
                  <Header/>
                  <Routes/>
              </BookingProvider>
          </StylesProvider>
      </BrowserRouter>
  );
}

export default App;
