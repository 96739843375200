import React, {useContext, useEffect, useState} from "react";
import {Badge, Button, Container, Grid, TextField, Typography} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Stepper from "../assets/Stepper.svg";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DaysUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import TimePicker from "../components/TimePicker";
import {useHistory, useLocation} from "react-router-dom";
import {getPeriods, getLocations, getCalendar} from "../services/ScheduleService";
import BookingSummary from "../components/booking-summary/BookingSummary";
import {BookingContext} from "../providers/BookingProvider";

require('dayjs/locale/es');
dayjs.locale('es');

const AppointmentPage = () => {

    const {booking, setBooking} = useContext(BookingContext);
    const history = useHistory();
    const [date, setDate] = useState(new Date());
    const[periods, setPeriods] = useState([]);
	const [locations, setLocations] = useState([]);
    const [calendar, setCalendar] = useState([]);

    const onSelectTime = (time) => {
        time = time.id !== booking.time?.id ? time : null;
        setBooking({...booking, time: time});
    }

    const onChangeDate = (date) => {

        setDate(date);
    }

    const onSelectLocation = (location) => {
        setBooking({...booking, location: location});
    }

    const nextPage = () => {
        history.push("/pacientes")
    }

    useEffect(async() =>{
        const result = await getPeriods();
        const times = result.map(period => {
            return {
                id: period.id,
                description: `${period.hourStart} a ${period.hourEnd} hs`,
                isAvailable: false
            }
        })
        setPeriods(times);
    }, [])


    const [selectedDays, setSelectedDays] = useState([28]);

	useEffect(async() =>{
        const result = await getLocations();
        setLocations(result);
    }, [])

    useEffect(async() =>{
        pullCalendar(dayjs());
    }, [])
    
    useEffect(() =>{
        if(calendar.length > 0){
            getNearDate();
        }
    }, [calendar])

    useEffect(() =>{
        pullPeriods();
    }, [date])

    useEffect(() =>{
        const period = periods.find(period => period.id === booking?.time?.id);
        setBooking({...booking, date: date, time: period?.isAvailable ? period : null});
    }, [periods])

    const onChangeCalendar = async(date) => {
        pullCalendar(date);
    }

    const pullCalendar = async(date) => {
        date = dayjs(date);
        const year = parseInt(date.format('YYYY'));
        const month = parseInt(date.format('MM'));
        const result = await getCalendar(year,month);
        setCalendar(result);
    }

    const pullPeriods = () => {
        const calendarPeriods = calendar.find(day => day.date === dayjs(date).format('YYYY-MM-DD'));
        setBooking({...booking, calendar: calendarPeriods});
        const times = periods.map(period => {
            const isAvailable = calendarPeriods?.periods.find(calendarPeriod => calendarPeriod?.period?.id === period.id)?.slots > 0;
            return {
                ...period,
                isAvailable: isAvailable
            }
        });
        setPeriods(times);
    }

    const getNearDate = () => {
        const today = dayjs().format('YYYY-MM-DD');
        let nearDate = calendar.find(schedule => dayjs(schedule.date) >= dayjs(today)).date;
        nearDate = nearDate ?? calendar.reverse().find(schedule => dayjs(schedule.date) <= dayjs(today))?.date;
        setDate(nearDate);
    }

    const disableDays = (date) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        const shouldDisable = calendar.some(schedule => schedule.date === formattedDate);
        return !shouldDisable;
    }

    return(
        <Container maxWidth={false} style={{backgroundColor: "#F0F2F9", paddingTop: 60, height: "100vh"}}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <div style={{backgroundColor: "#FFF", padding: "4rem", "borderRadius": 12}}>
                            <Grid container spacing={1} justify={"center"} >
                            <Grid item md={12}>
                                <img src={Stepper} style={{margin: "auto", display: "block"}}/>
                                <Typography variant={"h5"} component={"h2"} style={{marginTop: "1.5rem", color: "#6A6D81"}}>A continuación, selecciona tu comuna:</Typography>
                            </Grid>
                            <Grid item md={12} style={{marginBottom: "2rem"}}>
                                <Autocomplete
                                    options={locations}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => onSelectLocation(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth placeholder="Busca tu comuna" variant="outlined" />}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Typography variant={"h5"} component={"h2"} style={{color: "#6A6D81"}}>Elije un día y hora para tu test:</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <MuiPickersUtilsProvider utils={DaysUtils}>
                                    <DatePicker
                                        disableToolbar={true}
                                        autoOk
                                        variant="static"
                                        openTo="date"
                                        value={date}
                                        onChange={(d)=> onChangeDate(d)}
                                        renderDay={(day, selectedDate, isInCurrentMonth,dayComponent) => {
                                            const date = dayjs(day);
                                            const dayNumber = parseInt(date.format('D'));
                                            const isSelected = isInCurrentMonth && selectedDays.includes(dayNumber);
                                            return <div style={ isSelected ? {color: "#06D6A0"} : undefined }>{dayComponent}</div>;
                                        }}
                                        shouldDisableDate={disableDays}
                                        onMonthChange={onChangeCalendar}
                                        onYearChange={onChangeCalendar}
                                    />
                                </MuiPickersUtilsProvider>
                                <Typography variant={"body1"} component={"h2"} style={{color: "#6A6D81"}}>Puedes elegir otra fecha de calendario que veas disponible.</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <div style={{borderRadius: "4px", border: "1px solid #D4D2D8", padding: "2rem", textAlign: "center", marginBottom: "2rem"}}>
                                    <Typography variant={"body1"} component={"h2"} style={{color: "#597EF7"}}>Turno seleccionado</Typography>
                                    <Typography style={{fontSize:18, fontWeight:"bold", textTransform:"uppercase", color: "#6A6D81"}}>{dayjs(date).format("DD MMMM YYYY")}</Typography>
                                </div>
                                <Typography variant={"body1"} component={"h2"} style={{color: "#597EF7"}}>Franja horaria:</Typography>
                                <TimePicker onSelectTime={onSelectTime} times={periods} selectedTime={booking.time}/>
                            </Grid>

                        </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <BookingSummary/>
                    </Grid>
                    <Grid item xs={11}>
                        <Button variant={"outlined"} color={"secondary"} style={{float:"right"}} onClick={nextPage} disabled={!booking.location || !booking.date || !booking.time}>Continuar <ArrowRightAltIcon/></Button>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    )

}

export default AppointmentPage;