import React, { useContext } from "react";
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import Stepper3 from "../assets/Stepper3.svg";
import { EmailOutlined } from "@material-ui/icons";
import BookingSummary from "../components/booking-summary/BookingSummary";
import { bookAppointment } from "../services/AppointmentService";
import { BookingContext } from "../providers/BookingProvider";
import { createPayment, getPaymentURL } from "../services/PaymentService";
import { useFormik } from "formik";
import * as yup from "yup";
import { isValidRut } from "../validators/validators";

const PaymentPage = () => {
  const { booking } = useContext(BookingContext);

  const validations = yup.object({
    patientName: yup.string().required("El nombre es requerido"),
    patientLastName: yup.string().required("El apellido es requerido"),
    documentNumber: yup
      .string()
      .required("El RUT es requerido")
      .test("isValidRUT", "El RUT es inválido", (value) =>
        isValidRut(value)
      ),
    patientEmail: yup
      .string()
      .email("El correo electrónico es inválido")
      .required("El correo electrónico es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      patientName: "",
      patientLastName: "",
      documentNumber: "",
      patientEmail: "",
    },
    validationSchema: validations,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = () => {
    goToPaymentMethod();
  };

  const goToPaymentMethod = async () => {
    const appointment = await bookAppointment(booking);
    const email = formik.values.patientEmail;
    const payment = await createPayment(appointment.id, email);

    const url = await getPaymentURL(payment.id);
    window.location.href = url;
  };

  return (
    <Container
      maxWidth={false}
      style={{ backgroundColor: "#F0F2F9", paddingTop: 60, height: "100vh" }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <div
              style={{
                backgroundColor: "#FFF",
                padding: "4rem",
                borderRadius: 12,
              }}
            >
              <Grid
                container
                spacing={1}
                justify={"center"}
                direction={"column"}
              >
                <Grid item md={12}>
                  <img
                    src={Stepper3}
                    style={{
                      margin: "auto",
                      display: "block",
                      marginBottom: "1rem",
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Typography
                    variant={"h5"}
                    component={"h2"}
                    style={{
                      marginTop: "1.5rem",
                      marginBottom: "1.5rem",
                      color: "#6A6D81",
                    }}
                  >
                    Ingresa los datos del titular del medio de pago:
                  </Typography>
                </Grid>
                <form onSubmit={formik.handleSubmit} id={"form1"}>
                  <Grid item md={9} style={{ marginBottom: "1rem" }}>
                    <TextField
                      fullWidth
                      label={"Nombre"}
                      name={"patientName"}
                      variant="outlined"
                      value={formik.values.patientName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.patientName)}
                      helperText={formik.errors.patientName}
                    />
                  </Grid>

                  <Grid item md={9} style={{ marginBottom: "1rem" }}>
                    <TextField
                      fullWidth
                      label={"Apellido"}
                      name={"patientLastName"}
                      variant="outlined"
                      value={formik.values.patientLastName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.patientLastName)}
                      helperText={formik.errors.patientLastName}
                    />
                  </Grid>

                  <Grid item md={9} style={{ marginBottom: "1rem" }}>
                    <TextField
                      fullWidth
                      label={"RUT"}
                      name={"documentNumber"}
                      variant="outlined"
                      onKeyUp={
                        (event) => {
                            const completeRut = event.target.value.replace(
                              /[^0-9kK]/g,
                              ""
                            );
                            const dv = completeRut.slice(-1);
                            const rutWithOutDv = completeRut.slice(0, -1);
                            event.target.value = `${rutWithOutDv}-${dv}`;
                            formik.handleChange(event);
                          }
                      }
                      inputProps={
                        { maxLength: 10 }
                      }
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.documentNumber)}
                      helperText={formik.errors.documentNumber}
                    />
                  </Grid>

                  <Grid item md={9} style={{ marginBottom: "1rem" }}>
                    <TextField
                      fullWidth
                      label={"Correo electrónico"}
                      placeholder={"Correo electrónico"}
                      name={"patientEmail"}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlined
                              style={{ color: "#597EF7" }}
                              fontSize={"small"}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.patientEmail}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.patientEmail)}
                      helperText={formik.errors.patientEmail}
                    />
                  </Grid>

                  <Grid item md={9} style={{ marginBottom: "1rem" }}>
                    <Button
                      variant={"contained"}
                      color={"secondary"}
                      style={{ float: "right" }}
                      form={"form1"}
                      type={"submit"}
                    >
                      Ir al medio de pago
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={3}>
            <BookingSummary />
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default PaymentPage;
