import React, {useContext, useEffect, useState} from "react";
import {Button, Container, Grid, Typography} from "@material-ui/core";
import ServiceList from "../../components/service-list/ServiceList";
import {getAll} from "../../services/MedicalService"
import {useHistory} from "react-router-dom";
import styles from "./home.module.scss";
import {BookingContext} from "../../providers/BookingProvider";

const AsideHero = () => {
    return (
        <>
            <div className="description-container">
                <Typography color={"primary"} variant={"h6"} component={"h3"}>
                    Solicita tu test de Covid aquí sin salir de tu casa!
                </Typography>
            </div>
            <div className={"description2-container"}>
                <Typography variant={"body2"} style={{color: "#6A6D81", marginTop: "3rem", fontSize: 18}}>
                    Realízate el Test de Covid-19 en tu hogar, con toda la confianza de un equipo humano multidisciplinario altamente
                    capacitado, el cual responderá a todas tus consultas a la hora
                    de su visita.
                </Typography>
            </div>
        </>
    )
}

const Home = () => {
    const {booking, setBooking} = useContext(BookingContext);
    const [medicalServices, setMedicalServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const history = useHistory();

    useEffect(async() => {
        const services = await getAll();
        setMedicalServices(services);
    }, []);

    const selectService = (service) => {
        setSelectedService(service);
    }

    const goToAppointment = () => {
        setBooking({...booking,  service: selectedService});
        history.push('/cita')
    }

    return (
        <Container style={{paddingTop: 60}}>
            <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                    <Typography color={"primary"} variant={"h4"} component={"h1"}
                                style={{fontWeight: "bold", fontSize: 32}}>Realiza tu Test de Covid-19 en tu
                        hogar</Typography>

                </Grid>
                <Grid item xs={12} md={5}>
                    <AsideHero/>
                </Grid>
                <Grid item xs={12} md={7} style={{paddingLeft: "6rem"}}>
                    <Grid container item spacing={1} alignItems="flex-end">
                        <Typography color={"primary"} variant={"h4"} component={"h2"}>¿Qué tipo de análisis deseas
                            realizarte a domicilio?</Typography>
                    </Grid>

                    <Grid container spacing={2} item style={{marginTop: 50, textAlign: "center"}}>
                        <ServiceList services={medicalServices} onSelectService={selectService}
                                     selectedService={selectedService}/>
                    </Grid>

                    <Grid container item>
                        <Grid item>
                            <Button className={styles.request_button} variant={"contained"} color={"secondary"} disableElevation={true} size={"large"}
                                    style={{textTransform: "unset"}} disabled={!selectedService}
                                    onClick={goToAppointment}>Solicitar</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Home;