import httpRequest from "../http/HttpRequest";

const { REACT_APP_PAYMENT_URL } = process.env;

const paymentURL = REACT_APP_PAYMENT_URL;

const createPayment = async(appointmentId, email) => {
    const input = {
        appointmentId: appointmentId,
        email: email
    }
    const request = {
        query : ` mutation ($input: PaymentInput!){
            paymentCreate(input: $input){
                id,
                order,
                amount
            }
        }`,
        variables: {
            "input": input
        }
    };
    const response = await httpRequest.postRequest(``, request);
    return response.data?.data.paymentCreate || [];
}

const getPaymentURL = (paymentId) => {
    return `${paymentURL}/${paymentId}`
}

export {createPayment, getPaymentURL}