import React from "react";
import DeleteIcon from '@material-ui/icons/Delete';

const PatientsList = ({patients, onDelete}) => {
    return (
            <>
            <div style={{background: "#CCD7EE",  borderRadius: "8px 8px 0px 0px", padding: "10px 20px"}}>
                <table style={{width: "90%", color: "#4E5C93", fontWeight: "bold"}}>
                    <tr>
                        <td style={{width: "30%"}}>Nombre</td>
                        <td>Rut / pasaporte</td>
                        <td style={{width: "5%"}}>Acciones</td>
                    </tr>
                </table>
            </div>
            <div style={{padding: "10px 20px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                <table style={{width: "90%"}}>
                    {
                        patients.map (p =>
                            <tr key={p.documentNumber}>
                                <td style={{width: "30%"}}>{p.name} {p.lastname}</td>
                                <td>{p.documentNumber}</td>
                                <td style={{width: "5%"}}><DeleteIcon style={{color: "#7D90B2"}} onClick={() => onDelete(p)}/></td>
                            </tr>
                        )
                    }

                </table>
            </div>
            </>
    )
}

export default PatientsList;